import { motion, AnimatePresence, LayoutGroup } from "framer-motion";
import { FaFileExport, FaFileImport } from 'react-icons/fa';
import { Qrcode, Link } from 'tabler-icons-react';
//import { QRCodeStyling } from "qr-code-styling";
import { useTranslation } from 'react-i18next';
import React, { memo, useContext, useState, useRef, useEffect } from 'react';
import {
  SegmentedControl, Center, Box, Button, useMantineTheme, createStyles, DEFAULT_THEME, } from '@mantine/core';
import * as styles from './Actions.module.css';
import { Paper } from '@mantine/core';
import { useDispatch, useSelector } from '../../../../contexts/ResumeContext';
//import Button from '../../../shared/Button';
import Heading from '../../../shared/Heading';
import Input from '../../../shared/Input';
import ModalContext from '../../../../contexts/ModalContext';


const Actions = ({ id }) => {
  const { t } = useTranslation();

  const [loadDemoText, setLoadDemoText] = useState(
    t('builder.actions.loadDemoData.button'),
  );
  const [resetText, setResetText] = useState(
    t('builder.actions.resetEverything.button'),
  );

  const [ shareOption, setShareOption ] = useState('code')
  const [ animateOption, setAnimateOption ] = useState('show')

  const state = useSelector();
  const dispatch = useDispatch();
  const { emitter, events } = useContext(ModalContext);

  const handleImport = () => emitter.emit(events.IMPORT_MODAL);

  const handleExport = () => emitter.emit(events.EXPORT_MODAL);

  var qrcode = useRef( null );
  var qrCodeObj = null;
  var QRCodeStyling = null

  const theme = useMantineTheme();
  theme.colorScheme = 'dark';

  const classes = createStyles( ( theme ) => ( {
    button: {
      backgroundColor: theme.colors.indigo[ 5 ],
      '&:hover': {
        backgroundColor: theme.colors.indigo[ 7 ],
      },
    },
  } ) );

  const getSharableUrl = () => {
    const shareId = state.id;
    return `https://document.boardsi.com/r/${shareId}`;
  };

  useEffect(()=>{
    if ( typeof window !== "undefined" ) {
      QRCodeStyling = require( "qr-code-styling" );
      qrCodeObj = new QRCodeStyling( { "width": 192, "height": 192, "data": `https://document.boardsi.com/r/${state.id}`, "margin": 0, "qrOptions": { "typeNumber": "0", "mode": "Byte", "errorCorrectionLevel": "Q" }, "imageOptions": { "hideBackgroundDots": true, "imageSize": 1, "margin": 2 }, "dotsOptions": { "type": "extra-rounded", "color": "#000000", "gradient": { "type": "linear", "rotation": 3.961897402027128, "colorStops": [ { "offset": 0, "color": "#7575e1" }, { "offset": 1, "color": "#272b49" } ] } }, "backgroundOptions": { "color": "#ffffff", "gradient": { "type": "radial", "rotation": 0.9599310885968813, "colorStops": [ { "offset": 0, "color": "#ffffff" }, { "offset": 1, "color": "#fafaff" } ] } }, "image": "https://document.boardsi.com/images/boardsi-logo-flat-1200-768x172.webp", "dotsOptionsHelper": { "colorType": { "single": true, "gradient": false }, "gradient": { "linear": true, "radial": false, "color1": "#6a1a4c", "color2": "#6a1a4c", "rotation": "0" } }, "cornersSquareOptions": { "type": "extra-rounded", "color": "#000000", "gradient": { "type": "radial", "rotation": 0, "colorStops": [ { "offset": 0, "color": "#050505" }, { "offset": 1, "color": "#534fab" } ] } }, "cornersSquareOptionsHelper": { "colorType": { "single": true, "gradient": false }, "gradient": { "linear": true, "radial": false, "color1": "#000000", "color2": "#000000", "rotation": "0" } }, "cornersDotOptions": { "type": "dot", "color": "#3f3993", "gradient": null }, "cornersDotOptionsHelper": { "colorType": { "single": true, "gradient": false }, "gradient": { "linear": true, "radial": false, "color1": "#000000", "color2": "#000000", "rotation": "0" } }, "backgroundOptionsHelper": { "colorType": { "single": true, "gradient": false }, "gradient": { "linear": true, "radial": false, "color1": "#ffffff", "color2": "#ffffff", "rotation": "0" } } } );
      qrCodeObj.append( qrcode.current );
      const shareUrl = getSharableUrl();
      qrCodeObj.update( {
        data: shareUrl
      } );
    }

    setAnimateOption('show')
  }, [ shareOption ])

  const container = {
    hidden: { opacity: 1, scale: 1 },
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 1,
        staggerChildren: 1,
      }
    }
  };

  const item = {
    hidden: { y: 10, opacity: 0 },
    show: i => ( {
      y: 0,
      opacity: 1,
      transition: {
        delay: .1 * i,
        duration: .3,
        type: "spring",
        bounce: .1,
        stiffness: 400,
        damping: 100,
      }
    } )
  };

  const shareOptionChanged = () => {
    setAnimateOption('hidden');
    if(shareOption == "link") {
      setShareOption( 'code' )

      if ( typeof window !== "undefined" ) {
        setTimeout( () => {
          setAnimateOption( 'show' );
        }, 25 );
        setTimeout(()=>{
          qrCodeObj = new QRCodeStyling( { "width": 192, "height": 192, "data": `https://document.boardsi.com/r/${state.id}`, "margin": 0, "qrOptions": { "typeNumber": "0", "mode": "Byte", "errorCorrectionLevel": "Q" }, "imageOptions": { "hideBackgroundDots": true, "imageSize": 1, "margin": 2 }, "dotsOptions": { "type": "extra-rounded", "color": "#000000", "gradient": { "type": "linear", "rotation": 3.961897402027128, "colorStops": [ { "offset": 0, "color": "#7575e1" }, { "offset": 1, "color": "#272b49" } ] } }, "backgroundOptions": { "color": "#ffffff", "gradient": { "type": "radial", "rotation": 0.9599310885968813, "colorStops": [ { "offset": 0, "color": "#ffffff" }, { "offset": 1, "color": "#fafaff" } ] } }, "image": "https://document.boardsi.com/images/boardsi-logo-flat-1200-768x172.webp", "dotsOptionsHelper": { "colorType": { "single": true, "gradient": false }, "gradient": { "linear": true, "radial": false, "color1": "#6a1a4c", "color2": "#6a1a4c", "rotation": "0" } }, "cornersSquareOptions": { "type": "extra-rounded", "color": "#000000", "gradient": { "type": "radial", "rotation": 0, "colorStops": [ { "offset": 0, "color": "#050505" }, { "offset": 1, "color": "#534fab" } ] } }, "cornersSquareOptionsHelper": { "colorType": { "single": true, "gradient": false }, "gradient": { "linear": true, "radial": false, "color1": "#000000", "color2": "#000000", "rotation": "0" } }, "cornersDotOptions": { "type": "dot", "color": "#3f3993", "gradient": null }, "cornersDotOptionsHelper": { "colorType": { "single": true, "gradient": false }, "gradient": { "linear": true, "radial": false, "color1": "#000000", "color2": "#000000", "rotation": "0" } }, "backgroundOptionsHelper": { "colorType": { "single": true, "gradient": false }, "gradient": { "linear": true, "radial": false, "color1": "#ffffff", "color2": "#ffffff", "rotation": "0" } } } );
          qrCodeObj.append( qrcode.current );
          const shareUrl = getSharableUrl();
          qrCodeObj.update( {
            data: shareUrl
          } );
        }, 100);
      }
    } else {
      setAnimateOption( 'show' );
      setShareOption( 'link' )
      
    }
  }

  const handleOpenLink = () => {
    if (typeof window !== `undefined`) {
      window && window.open(getSharableUrl());
    }
  };

  const handleLoadDemo = () => {
    if (loadDemoText === t('builder.actions.loadDemoData.button')) {
      setLoadDemoText(t('shared.buttons.confirmation'));
      return;
    }

    dispatch({ type: 'load_demo_data' });
    setLoadDemoText(t('builder.actions.loadDemoData.button'));
  };

  const handleReset = () => {
    if (resetText === t('builder.actions.resetEverything.button')) {
      setResetText(t('shared.buttons.confirmation'));
      return;
    }

    setResetText(t('builder.actions.resetEverything.button'));
    dispatch({ type: 'reset_data' });
  };

  return (
    <section>
      <Heading id={id} />

      <motion.div layout custom={1} initial="hidden" animate={animateOption} variants={container}>
      <motion.div layout custom={2} initial="hidden" animate={animateOption} variants={item}>
      <Paper className={styles.container}>
        <h5>{t('builder.actions.import.heading')}</h5>

        <p className="leading-loose">{t('builder.actions.import.text')}</p>

        <div className="mt-4 flex">
          <Button size="xs" type="boardsi" onClick={handleImport} variant="filled">
            {t('builder.actions.import.button')}
          </Button>
        </div>
      </Paper>
      </motion.div>

      <motion.div layout custom={2} initial="hidden" animate={animateOption} variants={item}>
      <Paper className={styles.container}>
        <h5>{t('builder.actions.export.heading')}</h5>

        <p className="leading-loose">{t('builder.actions.export.text')}</p>

        <div className="mt-4 flex">
          <Button size="xs" type="boardsi" onClick={handleExport} className={classes.button} styles={{ backgroundColor: theme.colors.indigo[ 5 ] }} variant="filled">
            {t('builder.actions.export.button')}
          </Button>
        </div>
      </Paper>
      </motion.div>

      <Paper className={styles.container}>
        <h5>{t('builder.actions.share.heading')}</h5>
        <SegmentedControl
          size="xs"
          radius="sm" 
          value={shareOption}
          onChange={shareOptionChanged}
          data={[
            {
              value: 'code',
              label: (
                <Center>
                  <Qrcode size={16} />
                  <Box ml={10}>QR Code</Box>
                </Center>
              ),
            },
            {
              value: 'link',
              label: (
                <Center>
                  <Link size={16} />
                  <Box ml={10}>Link</Box>
                </Center>
              ),
            }
          ]}
        />
          {shareOption == "link" ? ( 
            
          <Input
            type="action"
            value={getSharableUrl()}
            onClick={handleOpenLink}
          /> ) : ( 
          <div styles={{ borderRadius: "1rem !important", overflow: "hidden" }}>
            <div className="canvasWrapper" styles={{ borderRadius: "1rem !important", overflow: "hidden" }} ref={qrcode} />
          </div>)}

      </Paper>

      <motion.div layout custom={2} initial="hidden" animate={animateOption} variants={item}>
      <Paper className={styles.container}>
        <h5>{t('builder.actions.loadDemoData.button')}</h5>

        <p className="leading-loose">
          {t('builder.actions.loadDemoData.text')}
        </p>

        <div className="mt-4 flex">
          <Button size="xs" type="boardsi" onClick={handleLoadDemo} className={classes.button} styles={{ backgroundColor: theme.colors.indigo[ 5 ] }} variant="filled">{loadDemoText}</Button>
        </div>
      </Paper>
      </motion.div>
      <motion.div layout custom={2} initial="hidden" animate={animateOption} variants={item}>
      <Paper className={styles.container}>
        <h5>{t('builder.actions.resetEverything.button')}</h5>

        <p className="leading-loose">
          {t('builder.actions.resetEverything.text')}
        </p>

        <div className="mt-4 flex">
          <Button size="xs" type="boardsi" onClick={handleReset} className={classes.button} styles={{ backgroundColor: theme.colors.red[ 5 ] }} variant="filled">{resetText}</Button>
        </div>
      </Paper>
      </motion.div>
      </motion.div>
    </section>
  );
};

export default memo(Actions);
