import { pdf, usePDF, Document, Page, Text, Image, View, StyleSheet } from '@react-pdf/renderer';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import React, { memo, useEffect, Fragment, useRef } from 'react';
import html2canvas from 'html2canvas';
// import html2pdf from 'html2pdf.js';
import { saveAs } from 'file-saver';
import * as styles from './Artboard.module.css';
import { useSelector } from '../../../contexts/ResumeContext';
import Castform from '../../../templates/Castform';
import Celebi from '../../../templates/Celebi';
import Gengar from '../../../templates/Gengar';
import Glalie from '../../../templates/Glalie';
import Onyx from '../../../templates/Onyx';
import Pikachu from '../../../templates/Pikachu';

const Artboard = () => {
  const state = useSelector();
  const { t } = useTranslation();
  const { id, name, metadata } = state;
  const { template } = metadata;

  let eventHandle = null
  let blobHandle = null;

  const pdfStyles = StyleSheet.create( {
    page: {
      backgroundColor: '#E4E4E4',
      width: "100%",
      height: "100vh"
    },
    section: {
      width: "100%",
      height: "105%"
    },
    profile_container: {
      height: '100%',
      width: '100%',
    },
  } );

  document.addEventListener( 'visibilitychange', function () {
    if ( document.visibilityState == 'hidden' ) {
//       navigator.sendBeacon( "/log.php", analyticsData );
      console.log("they left");
    }
  } );


  const generatePdfDocument = async ( documentData, fileName ) => {
      return new Promise((resolve, reject)=>{
        html2canvas( documentData, { allowTaint: false, useCORS: false, logging: true, scale: 4, foreignObjectRendering: false, proxy: "https://document.boardsi.com/canvas-image" } ).then((canvas)=>{
          window.devicePixelRatio = 1;
          canvas.style.position = "absolute";
          canvas.style.width = "100%";
          canvas.style.height = "100%";

          const ctx = canvas.getContext( '2d' );

          ctx.scale( 2, 2 );
          const image = canvas.toDataURL( "image/png", 1.0 );

          pdf(
            ( <Document>
              <Page wrap={true} size={[600, 1200]} style={pdfStyles.page}>
                <View style={pdfStyles.profile_container} wrap={true}>
                  <Image style={pdfStyles.section} source={image} />
                </View>
              </Page>
            </Document> )
          ).toBlob().then((blob)=>{
            window.URL.revokeObjectURL( image );

            saveAs( image, "boardsi.png" );
            saveAs( blob, fileName );
          });
        });


        // const worker = html2pdf().from(documentData).save();
        resolve(fileName);
      });
  };

  useEffect(()=>{
    if ( typeof window !== `undefined` ) {
      const templateHandle = window.document.querySelector( '#templateHandle' );
      const panHandle = window.panzoom(templateHandle, {
        zoomSpeed: 0.1,
        bounds: true,
        boundsPadding: 0.35,
        smoothScroll: {
          minVelocity: 5,
          amplitude: 0.05
        },
        animate: true,
        autocenter: false,
        defaultZoomSpeed: 0.1
      });
    }

    setTimeout( () => {
      if ( typeof window !== `undefined` ) {
        const pages = window.document.getElementById( "page" );

        if ( eventHandle == null ) {
          eventHandle = window.addEventListener( 'createPdf', () => {
            console.log( "createPdf event handler" );
            generatePdfDocument( pages, `boardsi-${id}.pdf` ).then( (result) => {
              console.log(result)
            } );
          }, { passive: true, capture: true } );
        }
      }
    }, 500, window );

    return () => {
      if ( eventHandle !== null ) {
        try {
          eventHandle.removeEventListener();
          eventHandle = null;
        } catch(e) {}
      }
    }


  }, [template]);


  return (
    <>
      <Helmet>
        <title>
          {name} | {t('shared.appName')}
        </title>
        
        <link rel="canonical" href={`https://document.boardsi.com/app/builder/${id}`} />
      </Helmet>

      <div id="templateHandle">
        <div id="page" className={(styles.container)}>
          <>
              {template === 'onyx' && <Onyx data={state} />}
              {template === 'pikachu' && <Pikachu data={state} />}
              {template === 'gengar' && <Gengar data={state} />}
              {template === 'castform' && <Castform data={state} />}
              {template === 'glalie' && <Glalie data={state} />}
              {template === 'celebi' && <Celebi data={state} />}
          </>
        </div>
      </div>

      
    </>
  );
};

export default memo(Artboard);
