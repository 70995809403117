import { navigate } from 'gatsby';
import { toast } from 'react-toastify';
import { showNotification } from '@mantine/notifications';
import { useTranslation } from 'react-i18next';
import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import * as styles from './builder.module.css';
import { Dialog, Group, Button, TextInput, Text } from '@mantine/core';
import { useDispatch } from '../../contexts/ResumeContext';
import { FileAlert } from 'tabler-icons-react';
import Artboard from '../../components/builder/center/Artboard';
//import Button from '../../components/shared/Button';
import DatabaseContext from '../../contexts/DatabaseContext';
import LeftSidebar from '../../components/builder/left/LeftSidebar';
import LoadingScreen from '../../components/router/LoadingScreen';
import RightSidebar from '../../components/builder/right/RightSidebar';
import SettingsContext from '../../contexts/SettingsContext';

const Builder = ({ id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { getResume } = useContext(DatabaseContext);
  const { isSideBarOpen } = useContext(SettingsContext);
  const [ opened, setOpened ] = useState(false);

  const handleLoadDemoData = () => {
    dispatch({ type: 'load_demo_data' });
    setOpened(false);
  };

  useEffect(() => {
    (async () => {
      const resume = await getResume(id);

      if (!resume) {
        navigate('/app/dashboard');
        showNotification({
          color: 'red',
          title: 'Error Loading Board Document',
          message: 'Are you sure this board document exists?',
          icon: <FileAlert/>
        });
        //toast.error(t('builder.toasts.doesNotExist'));
        return null;
      }

      if (resume.createdAt === resume.updatedAt) {
        setOpened(true);
        toast.dark(() => (
          <div className="py-2">
            <p className="leading-loose">{t('builder.toasts.loadDemoData')}</p>

            <Button size="xs" type="boardsi" className="mt-4" onClick={handleLoadDemoData}>
              {t('builder.actions.loadDemoData.button')}
            </Button>
          </div>
        ));
      }

      dispatch({ type: 'set_data', payload: resume });
      return setLoading(false);
    })();
  }, [id]);

  return useMemo(() => {
    if (loading) {
      return <LoadingScreen />;
    }

    return (
      <div className={styles.container}>
        <div className={styles.left}>
          <LeftSidebar />
        </div>
        <div className={`${styles.center} ${!isSideBarOpen && 'flex-1'}`}>
          <Artboard />
        </div>
        <div className={styles.right}>
          <RightSidebar />
        </div>
        <>
          <Dialog
            opened={opened}
            withCloseButton
            onClose={() => setOpened( false )}
            size="lg"
            radius="md"
          >
            <Text size="sm" style={{ marginBottom: 10 }} weight={500}>
              Do you want to load some demo data?
            </Text>

            <Group align="flex-end">
              <Button size="xs" type="boardsi" onClick={handleLoadDemoData}>Load Demo Data</Button>
              <Button size="xs" type="boardsi" color="gray" onClick={setOpened( false )}>Cancel</Button>
            </Group>
          </Dialog>
        </>
      </div>
    );
  }, [loading, isSideBarOpen, opened]);
};

export default memo(Builder);

export async function getServerData() {}