import { Link } from 'gatsby';
import { motion, AnimatePresence, LayoutGroup, useAnimation } from "framer-motion";
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React, { memo, useEffect } from 'react';
import Avatar from '../../shared/Avatar';
// import Logo from '../../shared/Logo';
import * as styles from './LeftNavbar.module.css';
import SectionIcon from '../../shared/SectionIcon';
import sections from '../../../data/leftSections';

const LeftNavbar = () => {
  const { t } = useTranslation();

  const container = {
    hidden: { opacity: 1, scale: 1 },
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: .15,
        staggerChildren: .15,
      }
    }
  };

  const item = {
    hidden: { y: 15, opacity: 0 },
    show: i => ( {
      y: 0,
      opacity: 1,
      transition: {
        delay: i * .1,
        duration: .2,
        type: "spring",
        bounce: .6,
        stiffness: 300,
        damping: 150,
      }
    } )
  };
 
  return (
    <div className={styles.container}>
      <Tooltip title={t('builder.tooltips.backToDashboard')} placement="right">
        <div>
          <Link to="/app/dashboard">
            <img className="w-auto h-full" src="https://document.boardsi.com/images/blk-icon-white-circle.png"/>
          </Link>
        </div>
      </Tooltip>

      <hr className="my-6" />

        <motion.div layout initial="hidden" animate="show" variants={container} className="grid grid-cols-1 gap-4 text-primary-500">
          <AnimatePresence>
            <LayoutGroup>
              {sections.map( ( x, i, a ) => (
                <motion.div layout key={( i + 2 )} custom={i} initial="hidden" animate="show" variants={item} style={{marginBottom: ".5rem", marginTop: ".5rem"}}>
                  <SectionIcon
                    key={x.id}
                    section={x}
                    containerId="LeftSidebar"
                    tooltipPlacement="right"
                  />
                </motion.div>
              ) )}
            </LayoutGroup>
          </AnimatePresence>
        </motion.div>

      <hr className="mt-auto my-6" />

      <Avatar />
    </div>
  );
};

export default memo(LeftNavbar);
