import { motion, AnimatePresence, LayoutGroup, useAnimation } from "framer-motion";
import { Element } from 'react-scroll';
import React, { Fragment, memo, useContext } from 'react';
import * as styles from './LeftSidebar.module.css';
import Awards from './sections/Awards';
import Certifications from './sections/Certifications';
import Character from './sections/Character';
import Analytical from './sections/Analytical';
import Communication from './sections/Communication';
import BoardExperience from './sections/BoardExperience';
import Strategic from './sections/Strategic';
import Knowledge from './sections/Knowledge';
import Education from './sections/Education';
import Hobbies from './sections/Hobbies';
import Languages from './sections/Languages';
import LeftNavbar from './LeftNavbar';
import Objective from './sections/Objective';
import Profile from './sections/Profile';
import Projects from './sections/Projects';
import References from './sections/References';
import Skills from './sections/Skills';
import Social from './sections/Social';
import Work from './sections/Work';
import sections from '../../../data/leftSections';
import SettingsContext from '../../../contexts/SettingsContext';

const getComponent = (id) => {
  switch (id) {
    case 'profile':
      return Profile;
    case 'social':
      return Social;
    case 'objective':
      return Objective;
    case 'work':
      return Work;
    case 'education':
      return Education;
    case 'projects':
      return Projects;
    case 'awards':
      return Awards;
    case 'certifications':
      return Certifications;
    case 'character':
      return Character;
    case 'analytical':
      return Analytical;
    case 'communication':
      return Communication;
    case 'strategic':
      return Strategic;
    case 'boardExperience':
      return BoardExperience;
    case 'knowledge':
      return Knowledge;
    case 'skills':
      return Skills;
    case 'hobbies':
      return Hobbies;
    case 'languages':
      return Languages;
    case 'references':
      return References;
    default:
      throw new Error();
  }
};

const SidebarSection = ({ id, event }) => {
  const Component = getComponent(id);

  const item = {
    hidden: { y: 40, opacity: 0 },
    show: i => ( {
      y: 0,
      opacity: 1,
      transition: {
        delay: .1,
        duration: .25,
        type: "spring",
        bounce: .25,
        stiffness: 450,
        damping: 100,
      }
    } )
  };

  return (
    <Fragment key={id}>
      <motion.div layout key={id} initial="hidden" whileInView="show" viewport={{ once: true }} variants={item}>
        <Element name={id}>
          <Component id={id} event={event} />
        </Element>
      </motion.div>
      <hr />
    </Fragment>
  );
};

const LeftSidebar = () => {
  const { isSideBarOpen } = useContext(SettingsContext);
  return (
    <div className="flex">
      <LeftNavbar />
      {isSideBarOpen && (
        <div id="LeftSidebar" className={styles.container}>
          {sections.map(SidebarSection)}
        </div>
      )}
    </div>
  );
};
export default memo(LeftSidebar);
