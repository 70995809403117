import { useTranslation } from 'react-i18next';
import React, { memo } from 'react';
import Heading from '../../../shared/Heading';
import Input from '../../../shared/Input';
import { InfoSquare } from 'tabler-icons-react';

const Character = ({ id }) => {
  const { t } = useTranslation();

  return (
    <section>
      <div className="flex items-center"><Heading id={id} info={true}/><InfoSquare className="ml-4" size={20} /></div>

      <Input
        type="textarea"
        label={t('shared.forms.summary')}
        path="character.body"
      />
    </section>
  );
};

export default memo(Character);
