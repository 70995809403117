import { graphql, useStaticQuery } from 'gatsby';
import { ArrowBigLeft, ArrowBigRight } from 'tabler-icons-react';
import { motion, AnimatePresence, LayoutGroup } from "framer-motion";
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { memo,  useState } from 'react';
import cx from 'classnames';
import * as styles from './Templates.module.css';
import { Grid, Skeleton, Container, Button, Group } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { handleKeyUp } from '../../../../utils';
import { useDispatch, useSelector } from '../../../../contexts/ResumeContext';
import Heading from '../../../shared/Heading';
import templateOptions from '../../../../data/templateOptions';
import Carousel from 'react-elastic-carousel'

const Templates = ({ id }) => {
  const dispatch = useDispatch();
  const template = useSelector('metadata.template');

  const [carousel, setCarousel ] = useState({slideNext: ()=>{}, slidePrev: ()=>{}});

  const matches = useMediaQuery('(max-width: 2000px)', false);

  const container = {
    hidden: { opacity: 1, scale: 1 },
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: .75,
        staggerChildren: 1,
      }
    }
  };

  const item = {
    hidden: { y: 40, opacity: 0 },
    show: i => ( {
      y: 0,
      opacity: 1,
      transition: {
        delay: i * .16,
        duration: .2,
        type: "spring",
        bounce: .25,
        stiffness: 400,
        damping: 150,
      }
    } )
  };

  const previews = useStaticQuery(graphql`
    query {
      onyx: file(relativePath: { eq: "templates/onyx.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 240)
        }
      }
      pikachu: file(relativePath: { eq: "templates/pikachu.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 240)
        }
      }
      gengar: file(relativePath: { eq: "templates/gengar.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 240)
        }
      }
      castform: file(relativePath: { eq: "templates/castform.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 240)
        }
      }
      glalie: file(relativePath: { eq: "templates/glalie.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 240)
        }
      }
      celebi: file(relativePath: { eq: "templates/celebi.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 240)
        }
      }
    }
  `);

  const handleClick = (value) => {
    dispatch({
      type: 'on_input',
      payload: {
        path: 'metadata.template',
        value,
      },
    });
  };

  const myArrow = ( type, onClick, isEdge ) => {
    return (<></>)
  }

  return (
    <section>
      <Heading id={id} />
      <motion.div layout initial="hidden" animate="show" variants={container}>
        <Container size={"fluid"}>
          <Carousel itemPadding={[5, 10]} ref={ref => (setCarousel(ref))} itemsToShow={matches ? 1:2} renderArrow={myArrow} renderPagination={({ pages, activePage, onClick }) => {return (<></>)}}>
          {templateOptions.map((x, i, a) => (
              <div key={x.name} tabIndex="0" role="button" onKeyUp={(e) => handleKeyUp(e, () => handleClick(x.id))} onClick={() => handleClick(x.id)}
                className={cx(styles.template, {
                  [styles.selected]: template === x.id,
                })}
              >
                <GatsbyImage
                  alt={x.name}
                  image={previews[x.id].childImageSharp.gatsbyImageData}
                />
                <span>{x.name}</span>
              </div>
                ))}
          </Carousel>
          <Group p={10} grow spacing={10}>
            <Button onClick={()=>{carousel.slidePrev()}} leftIcon={<ArrowBigLeft size={16} />} variant="default">
              Prev
            </Button>
            <Button onClick={()=>{carousel.slideNext()}} rightIcon={<ArrowBigRight size={16} />} variant="default">
              Next
            </Button>
          </Group>
        </Container>
      </motion.div>
    </section>
  );
};

export default memo(Templates);
