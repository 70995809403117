import { Link } from 'react-scroll';
//import { Tooltip } from '@material-ui/core';
import { Tooltip } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import React, { memo } from 'react';
import * as styles from './SectionIcon.module.css';

const SectionIcon = ({ section, containerId, tooltipPlacement }) => {
  const { t } = useTranslation();
  const { id, icon: Icon } = section;

  return (
    <Tooltip
      label={t(`builder.sections.${id}`)}
      position={tooltipPlacement}
      openDelay={50}
      closeDelay={350}
      transition="pop"
      transitionDuration="100"
      transitionTimingFunction="ease-in-out"
      gutter={10}
      radius="sm"
    >
      <Link
        spy
        smooth
        to={id}
        offset={-18}
        duration={800}
        containerId={containerId}
        activeClass="text-primary-900"
        className={styles.icon}
      >
        <Icon size="20px" />
      </Link>
    </Tooltip>
  );
};

export default memo(SectionIcon);
