import { ActionIcon } from '@mantine/core';
import { DeviceFloppy } from 'tabler-icons-react';
import React, { memo, useContext } from 'react';
import { Loader } from '@mantine/core';
import DatabaseContext from '../../../contexts/DatabaseContext';

const SyncIndicator = () => {
  const { isUpdating } = useContext(DatabaseContext);

  return (
    isUpdating ? (<Loader color="teal"/>) : 
    <ActionIcon color="teal" size="xl">
      <DeviceFloppy/>
    </ActionIcon>
  );
};

export default memo(SyncIndicator);
