/* eslint-disable jsx-a11y/control-has-associated-label */
import { useTranslation } from 'react-i18next';
import React, { memo } from 'react';
import * as styles from './Colors.module.css';
import { handleKeyUp } from '../../../../utils';
import { useDispatch, useSelector } from '../../../../contexts/ResumeContext';
import Heading from '../../../shared/Heading';
import Input from '../../../shared/Input';
import colorOptions from '../../../../data/colorOptions';
import { ColorPicker }from '@mantine/core';

const Colors = ({ id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const primary = useSelector("metadata.colors.primary", '');
  const text = useSelector("metadata.colors.text", '');
  const background = useSelector("metadata.colors.background", '');

  const handleClick = (value) => {
    dispatch({
      type: 'on_input',
      payload: {
        path: 'metadata.colors.primary',
        value,
      },
    });
  };

  return (
    <section>
      <Heading id={id} />

      <ColorPicker size="lg" className="mx-auto" onChange={(color)=>{handleClick(color)}} format="hex" swatches={colorOptions} />

      <Input
        type="color"
        name="primary"
        swatches={colorOptions}
        label={t('builder.colors.primary')}
        placeholder="#FF4081"
        path="metadata.colors.primary"
        value={primary}
        picker={false}
      />

      <Input
        type="color"
        name="text"
        label={t('builder.colors.text')}
        placeholder="#444444"
        path="metadata.colors.text"
        value={text}
      />

      <Input
        type="color"
        name="background"
        label={t('builder.colors.background')}
        placeholder="#FFFFFF"
        path="metadata.colors.background"
        value={background}
      />
    </section>
  );
};

export default memo(Colors);
