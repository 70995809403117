import { useTranslation } from 'react-i18next';
import React, { memo } from 'react';
import { useSelector } from '../../contexts/ResumeContext';

const Heading = ({ id, info=false}) => {
  const { t } = useTranslation();
  const heading = useSelector(`${id}.heading`, t(`builder.sections.${id}`));

  return info ? (<div className="text-4xl focus:outline-none inline-flex">{heading}</div>) : (<h2 className="text-4xl focus:outline-none">{heading}</h2>)
};

export default memo(Heading);
