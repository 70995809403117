import { Draggable } from 'react-beautiful-dnd';
//import { Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React, { memo, useState } from 'react';
//import Switch from '@material-ui/core/Switch';
import { useDispatch } from '../../../contexts/ResumeContext';
import * as styles from './ListItem.module.css';
import { Badge, Switch, Menu, Button, Paper, Transition, Divider, createStyles, Text } from '@mantine/core';
import { Trash, DotsCircleHorizontal, ArrowUpCircle, ArrowDownCircle, EditCircle } from 'tabler-icons-react';

const dataTestIdPrefix = 'list-item-';

const ListItem = ({
  title,
  subtitle,
  text,
  path,
  data,
  isFirst,
  isLast,
  onEdit,
  index,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteClickCount, setDeleteClickCount] = useState(0);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const toggleSwitchState = 'isVisible' in data ? data.isVisible : true;

  const handleClose = () => setAnchorEl(null);

  const handleEdit = () => {
    onEdit();
    handleClose();
  };

  const useStyles = createStyles( ( theme ) => ( {
    button: {
      borderRadius: 0,

      '&:not(:first-of-type)': {
        borderLeftWidth: 0,
      },

      '&:first-of-type': {
        borderTopLeftRadius: theme.radius.sm,
        borderBottomLeftRadius: theme.radius.sm,
      },

      '&:last-of-type': {
        borderTopRightRadius: theme.radius.sm,
        borderBottomRightRadius: theme.radius.sm,
      },
    },
    itemHovered: {
      backgroundColor: theme.colors.dark[ 4 ] + " !important",
    }
  } ) );

  const { classes } = useStyles();


  const handleMoveUp = () => {
    dispatch({
      type: 'on_move_item_up',
      payload: {
        path,
        value: data,
      },
    });

    handleClose();
  };

  const handleMoveDown = () => {
    dispatch({
      type: 'on_move_item_down',
      payload: {
        path,
        value: data,
      },
    });

    handleClose();
  };

  const handleDelete = () => {
    dispatch({
      type: 'on_delete_item',
      payload: {
        path,
        value: data,
      },
    });

    handleClose();
  };

  const handleToggleUse = () => {
    dispatch({
      type: 'on_toggle_use_item',
      payload: {
        path,
        value: data,
      },
    });
  };

  const checkConfirmationAndDelte = () => {
    if (deleteClickCount === 1) {
      handleDelete();
    } else if (deleteClickCount < 1) {
      setDeleteClickCount(deleteClickCount + 1);
    }
  };

  return (
    <Draggable draggableId={data.id} index={index}>
      {(dragProvided) => (
        <Paper
          radius="sm"
          ref={dragProvided.innerRef}
          className={styles.listItem}
          data-testid={`${dataTestIdPrefix}${path}`}
          {...dragProvided.draggableProps}
          {...dragProvided.dragHandleProps}
        >
          <div className="grid">
            <span className="font-medium truncate"><Badge radius="sm" size="xs" variant="light">{title}</Badge></span>

            {subtitle && (
              <span className="mt-1 text-sm opacity-75 truncate">
                {subtitle}
              </span>
            )}

            {text && (
              <span className="w-4/5 mt-5 text-sm opacity-75 truncate">
                {text}
              </span>
            )}
          </div>
          <div className={styles.toggleButton}>
            <Switch
              radius="md"
              size="xs"
              checked={toggleSwitchState}
              onChange={handleToggleUse}
              color="primary"
              name="toggleSwitch"
              className="toggle-button"
            />
            <div className={styles.menu}>
            <Menu zIndex="2080" gutter="2" p={6} size="sm" placement="center" onClose={() => {
                  setDeleteClickCount(0);
                  handleClose();
                }} target={<DotsCircleHorizontal
                  size="18px"
                  aria-haspopup="true"
                  onClick={handleClick}
                />}>
              <Menu.Item pt={6} pb={6} pr={12}  pl={12} disabled={isFirst} onClick={handleMoveUp} icon={<ArrowUpCircle size={14} />}><Text size="xs">Move Up One</Text></Menu.Item>
              <Menu.Item pt={6} pb={6} pr={12}  pl={12} disabled={isLast} onClick={handleMoveDown} icon={<ArrowDownCircle size={14} />}><Text size="xs">Move Down One</Text></Menu.Item>
              <Divider />
              <Menu.Item pt={6} pb={6} pr={12}  pl={12} onClick={handleEdit} icon={<EditCircle size={14} />}><Text size="xs">Edit</Text></Menu.Item>
              <Divider />
              <Button pt={6} pb={6} pr={12}  pl={12} compact fullWidth mt={10} type="boardsi" variant="subtle" color="red" onClick={checkConfirmationAndDelte}>
              <Text size="xs">{deleteClickCount
                      ? t('shared.buttons.confirmation')
                      : t('shared.buttons.delete')}</Text>
                </Button>
            </Menu>
            </div>
          </div>

        </Paper>
      )}
    </Draggable>
  );
};

export default memo(ListItem);

export { dataTestIdPrefix };
